import axios from '../config/axiosConfig';

/* Elenco dei risultati dei livelli */
export const listResultMultiplayer = async (idUser) => {
  try {
    console.log(idUser)
    const response = await axios.post('/api/multiplayerResult/list', { idUser });
    return response.data;
  } catch (error) {
    console.error('Error fetching multiplayer results: ', error);
    throw error;
  }
};

/* Ottieni immagine multiplayer specifica */
export const getSpecificMultiplayerImage = async (id) => {
  try {
    const response = await axios.get(`/api/multiplayerResult/getImage/${id}`);
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching details specific image:', error);
    throw error;
  }
};

/* Dettagli risultato multiplayer */
export const resultMultiplayerDetails = async (id) => {
  try {
    const response = await axios.get(`/api/multiplayerResult/status/processing/details/${id}`);
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching details multiplayer result:', error);
    throw error;
  }
};


/* Modifica risultato multiplayer */
export const deleteResultMultiplayer = async (id) => {
  try {
    const response = await axios.put('/api/multiplayerResult/delete', { id });
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error deleting multiplayer result:', error);
    throw error;
  }
};
